/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';

import Layout from '../components/layout';
import SEO from '../components/seo';

import './our-work.scss';

const trucksterLogo = require('../images/Truckster-thumbnail.png');
const farmLogo = require('../images/Farmland-thumbnail.png');
const joymapLogo = require('../images/Joymap-thumbnail.png');
const MNELogo = require('../images/MNE-thumbnail.png');
const JRLogo = require('../images/JR-thumbnail.png');
const godGiftLogo = require('../images/GG-thumbnail.png');
const comigoLogo = require('../images/Comigo-thumbnail.png');
const pastPresentLogo = require('../images/PP-thumbnail.png');
const playmeLogo = require('../images/Playme-thumbnail.png');
const gotivationLogo = require('../images/Gotivation-thumbnail.png');
const apolloLogo = require('../images/Apollo-thumbnail.png');
const raiLogo = require('../images/RestorationAI-thumbnail.png');

const ProjectImage = ({ img, alt, index = 0 }) => {
  const [isHover, setHover] = useState(false);

  const fade = useSpring({
    opacity: 1, delay: index * 400, transform: 'translateY(0)', from: { opacity: 0, transform: 'translateY(60px)' }, config: { duration: 300 },
  });
  const hoverStyle = useSpring({ width: !isHover ? '100%' : '90%', height: !isHover ? '100%' : '90%' });

  return (
    <animated.div
      style={fade}
      className="Project-Image"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <animated.img style={hoverStyle} src={img} alt={alt} />
    </animated.div>
  );
};

const OurWork = () => {
  const fade = (delay = 0) => useSpring({
    opacity: 1, delay, transform: 'translateY(0)', from: { opacity: 0, transform: 'translateY(60px)' }, config: { duration: 300 },
  });

  return (
    <Layout>
      <SEO title="Our work" />
      <div className="OurWork">
        <animated.div style={fade(0)} className="OurWork__Title">Our work</animated.div>
        <div className="OurWork__List">
          <ProjectImage img={trucksterLogo} alt="truckster" index={1} />
          <ProjectImage img={apolloLogo} alt="apollo" index={2} />
          <ProjectImage img={comigoLogo} alt="comigo" index={3} />
          <ProjectImage img={farmLogo} alt="farm land" index={4} />
          <ProjectImage img={raiLogo} alt="RAI" index={5} />
          <ProjectImage img={joymapLogo} alt="joymap" index={6} />
          <ProjectImage img={MNELogo} alt="MNE" index={7} />
          <ProjectImage img={JRLogo} alt="job republic" index={8} />
          <ProjectImage img={godGiftLogo} alt="god's gift" index={9} />
          <ProjectImage img={pastPresentLogo} alt="past present" index={10} />
          <ProjectImage img={playmeLogo} alt="play me" index={11} />
          <ProjectImage img={gotivationLogo} alt="gotivation" index={12} />
        </div>
      </div>
    </Layout>
  );
};

export default OurWork;
